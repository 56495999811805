<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-danger ml-1">Certificate Providing System</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome to Certificate Providing System👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- form -->
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
            @keydown="form.onKeydown($event)"
          >
            <!-- email -->
            <b-form-group label="Email" label-for="login-email">
              <b-form-input
                id="login-email"
                v-model="form.email"
                name="login-email"
                placeholder="anujit@example.com"
                :class="{
                  'is-invalid border-danger': form.errors.has('email'),
                }"
              />
              <has-error :form="form" field="email"></has-error>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">Password</label>
              </div>
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="login-password"
                  v-model="form.password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-password"
                  placeholder="············"
                  :class="{
                    'is-invalid border-danger': form.errors.has('password'),
                  }"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <has-error :form="form" field="password"></has-error>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="form.status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="danger"
              block
              :disabled="form.busy"
            >
              Sign in
            </b-button>
          </b-form>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";

import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { HasError, AlertError } from "vform/src/components/bootstrap5";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    HasError,
    AlertError,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: new Form({
        password: null,
        email: null,
        status: false,
      }),
      sideImg: require("@/assets/images/pages/login-v2.svg"),
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties, quotes
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },

  created() {
    const userCookie = this.getCookie("user");
    if (userCookie) {
      this.form.status = true;
      this.form.email = userCookie.email;
      this.form.password = userCookie.password;
      this.login();
    }
  },

  methods: {
    login() {
      if (this.form.status) {
        const userDataJSON = JSON.stringify({
          email: this.form.email,
          password: this.form.password,
        });
        this.setCookie("user", userDataJSON, 1);
      }

      this.form
        .post("/login", this.form)
        .then((res) => {
          if (res.data) {
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("role", res.data.data.role);
            localStorage.setItem("user_name", res.data.data.name);
            localStorage.setItem("email", res.data.data.email);
            this.$store.dispatch("setToken", res.data.data);
            this.s(res.data.message);
            this.$router.push({
              name: "dashboard",
            });
          }
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },

    setCookie(name, value, days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      const expires = `expires=${date.toUTCString()}`;
      document.cookie = `${name}=${value}; ${expires}; path=/`;
    },

    getCookie(name) {
      const cookieName = `${name}=`;
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        while (cookie.charAt(0) === " ") {
          cookie = cookie.substring(1);
        }
        if (cookie.indexOf(cookieName) === 0) {
          return JSON.parse(cookie.substring(cookieName.length, cookie.length));
        }
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
